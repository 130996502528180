import React, { useState, useEffect } from "react";

function App() {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [logoText, setLogoText] = useState("");
  const [imageUrl, setImageUrl] = useState(() => {
    const storedValue = localStorage.getItem("imageUrl");
    return storedValue ? JSON.parse(storedValue) : null;
  });

  const [middleImage, setMiddleImage] = useState(() => {
    const storedValue = localStorage.getItem("middleImage");
    return storedValue ? JSON.parse(storedValue) : null;
  });

  const [color, setColor] = useState("");
  const [backgroundC, setBackgroundC] = useState("");
  const [updateTime, setUpdateTime] = useState(30);
  const [barcodes, setBarcodes] = useState("");
  const [passType, setPassType] = useState("Generic");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageUrl !== null) {
      localStorage.setItem("imageUrl", JSON.stringify(imageUrl));
    }
  }, [imageUrl]);
  useEffect(() => {
    if (middleImage !== null) {
      localStorage.setItem("middleImage", JSON.stringify(middleImage));
    }
  }, [middleImage]);

  const hexToRgb = (hex) => {
    let r = 0,
      g = 0,
      b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  // Handle color change
  const handleColorChange = (e) => {
    setColor(e.target.value);
  };
  const handlebColorChange = (e) => {
    setBackgroundC(e.target.value);
  };
  const handleUpdateTime = (e) => {
    setUpdateTime(Number(e.target.value));
  };
  const handlePassType = (e) => {
    setPassType(e);
  };
  const handleBarcodes = (e) => {
    setBarcodes(e.target.value);
  };

  const update = async () => {
    const response = await fetch("http://192.168.7.57:3003/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        role,
        logoText,
        imageUrl,
        middleImage,
        color,
      }),
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let c = hexToRgb(color);
    let bc = hexToRgb(backgroundC);

    try {
      const response = await fetch(
        "https://admlabz.com/applepass/generate-pass",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            role,
            logoText,
            imageUrl,
            middleImage,
            c,
            bc,
            updateTime,
            barcodes,
            passType,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${name}_Pass.pkpass`); // This sets the filename for the downloaded pass
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error("Failed to generate pass:", error);
      alert("Error");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="fixed w-full top-0 left-0 p-4 text-center font-bold bg-blue-500 text-white">
        <h2>Generate Your Pass</h2>
      </div>
      <div className="w-full  pt-16 p-2">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">
              {" "}
              {passType == "Store" ? "Name Right" : "Role Under Name:"}{" "}
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border-2 p-4"
              onClick={(e) => setName("")}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">
              {passType == "Store" ? "Name Left" : "Role Under Name:"}{" "}
            </label>
            <input
              type="text"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="border-2 p-4"
              onClick={(e) => setRole("")}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">Logo Text Top:</label>
            <input
              type="text"
              value={logoText}
              onChange={(e) => setLogoText(e.target.value)}
              className="border-2 p-4"
              onClick={(e) => setLogoText("")}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">Image Url</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="border-2 p-4"
              onClick={(e) => setImageUrl("")}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">Image Middle</label>
            <input
              type="text"
              value={middleImage}
              onChange={(e) => setMiddleImage(e.target.value)}
              className="border-2 p-4"
              onClick={(e) => setMiddleImage("")}
            />
          </div>
          <div className="flex justify-around gap-4">
            <div className="flex flex-col w-full">
              <label className="font-bold w-full text-lg">Color</label>
              <input type="color" value={color} onChange={handleColorChange} />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-bold w-full text-lg">
                Background Color
              </label>
              <input
                type="color"
                value={backgroundC}
                onChange={handlebColorChange}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">Time Update</label>
            <input
              type="number"
              value={updateTime}
              onChange={handleUpdateTime}
              className="border-2 p-4"
              onClick={(e) => (e.target.value = "")}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">Pass Type</label>
            <div className="flex justify-around gap-2">
              <div
                className={`w-full p-2 rounded-lg text-center ${
                  passType == "Generic" ? "bg-blue-500" : "bg-blue-100"
                }`}
                onClick={() => setPassType("Generic")}
              >
                Generic
              </div>
              <div
                className={`w-full p-2 rounded-lg text-center ${
                  passType == "Store" ? "bg-blue-500" : "bg-blue-100"
                }`}
                onClick={() => setPassType("Store")}
              >
                Store
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="font-bold w-full text-lg">Barcodes</label>
            <input
              type="text"
              value={barcodes}
              onChange={handleBarcodes}
              className="border-2 p-4"
              onClick={(e) => (e.target.value = "")}
            />
          </div>
          <div className="flex justify-around w-full p-2 gap-2">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-4 rounded-lg flex justify-center items-center"
            >
              {loading ? (
                <img src="https://i.gifer.com/ZKZg.gif" className="w-[25px]" />
              ) : (
                "Generate Pass"
              )}
            </button>
            <button
              onClick={update}
              className="w-full bg-blue-500 text-white p-4 rounded-lg"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
